<template>
  <div class="test_page">
    <div class="content">
      <div class="total_num">
        <span class="red">{{ qsIndex }}</span> / {{ totalNum }}
      </div>

      <div :key="qsIndex">
        <!-- 题目 -->
        <!-- <h2 v-if="qsIndex > 0">第 {{ qsIndex }} 题</h2> -->
        <h2></h2>
        <div class="qs_title">{{ qsItem.title }}</div>
        <h2 v-html="qsItem.content"></h2>
        <template v-if="qsItem.type_code == 'pri'">
          <ul
            v-if="qsItem.choices && qsItem.choices.length > 0"
            class="choices_items"
          >
            <li v-for="(qs, index) in qsItem.choices" :key="index">
              <el-button
                :disabled="qs.disabled"
                @click="choiseAnswer(qs, qsItem.choices, qsItem)"
              >
                {{ qs.title }}</el-button
              >
            </li>
          </ul>
        </template>
        <template v-else>
          <ul
            v-if="qsItem.choices && qsItem.choices.length > 0"
            class="choices_items single_select_qs"
          >
            <li
              v-for="(qs, index) in qsItem.choices"
              :key="index"
              @click="choiseAnswer(qs, qsItem.choices, qsItem)"
              :class="{ active_answer: qs.is_select }"
            >
              {{ qs.title }}
            </li>
          </ul>
        </template>
        <!-- 排序答案 -->
        <div class="qs_answer_order_wrap">
          <ul>
            <li
              v-for="(tag, index) in qsItem.localStoreAnswer"
              :key="tag.title"
            >
              <el-tag closable @close="handleRemove(tag, qsItem, index)">
                {{ tag.title }}
              </el-tag>
            </li>
          </ul>
        </div>
      </div>

      <div class="btn">
        <el-button
          type="primary"
          v-if="qsIndex < qsList.length"
          @click="nextQs()"
          >下一题</el-button
        >
        <el-button
          type="primary"
          v-if="qsIndex == qsList.length && !loading"
          @click="submit()"
          >提 交</el-button
        >
        <el-button
          type="primary"
          class="nouse_btn"
          v-if="qsIndex == qsList.length && loading"
          >提 交</el-button
        >
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="40%">
      <span>恭喜您完成测评！</span>
      <a href="javascript:;" @click="toTestResult">点我查看测评结果</a>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      qsItem: {},
      totalNum: 0,
      qsList: [],
      testType: "",
      qsIndex: 0,
      qsResults: {},
      activeAnswerIndex: -1,
      answersList: [],
      dialogVisible: false,
      loading: false,
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    getDataList() {
      if (Object.keys(this.$route.params).length > 0) {
        this.testType = this.$route.params.type;
        console.log("testType", this.testType);
        this.qsIndex = parseInt(localStorage.getItem("currentQsIndex")||null);
      }

      this.qsResults = JSON.parse(localStorage.getItem("QuestionsStore"))|| [];
      this.qsList = this.qsResults.results || [];

      // console.log("题目", this.qsList);
      this.totalNum = this.qsList.length;
      this.getCurrentQs();
      this.getQs();
    },
    getCurrentQs() {
      this.qsItem = this.qsList.find((item, index) => {
        return this.qsIndex - 1 == index;
      });
    },
    getQs() {
      this.qsList.forEach((item, index) => {
        if (this.qsIndex - 1 == index) {
          this.qsItem = item;
        }
      });
    },
    //下一题
    nextQs() {
      //校验是否选择的答案
      const isPass = this.checkIsChoose(this.qsItem);
      if (isPass) {
        this.getAnswerSelect(this.qsItem);
        if (this.qsIndex < this.totalNum) {
          this.qsIndex++;
          localStorage.setItem("currentQsIndex", this.qsIndex);
          this.getQs();
        }
      }
    },
    //判断当前问题是否已选择
    checkIsChoose(data) {
      if (data.choices && data.choices.length > 0) {
        if (data.type_code == "pri") {
          //排序题
          if (data.localStoreAnswer.length == data.choices.length) {
            return true;
          } else {
            this.$toast("请依次点击选项，完成排序");
            return false;
          }
        } else {
          let result = data.choices.some(item => {
            return item.is_select;
          });
          if (!result) {
            this.$toast("请选择问题答案");
            return false;
          } else {
            return true;
          }
        }
      }
    },
    choiseAnswer(item, choicesData, parentObj) {
      // console.log("parentObj", parentObj);
      parentObj.is_select = true;
      if (parentObj.type_code === "pri") {
        item.disabled = true;
        parentObj.localStoreAnswer.push(item);
      } else {
        choicesData.forEach(item => {
          item.is_select = false;
        });
      }
      item.is_select = true;
    },
    //获取答案
    getAnswerSelect(obj) {
      let answers = [];
      if (obj.type_code === "pri") {
        answers = obj.localStoreAnswer;
      } else {
        answers = obj.choices.filter(item => {
          return item.is_select;
        });
      }
      const answerStore = {
        question_id: obj.id,
        answers: answers
      };
      this.answersList = JSON.parse(localStorage.getItem("answerList")) || [];
      this.answersList.push(answerStore);
      localStorage.setItem("answerList", JSON.stringify(this.answersList));
      console.log("答案", this.answersList);
    },
    //移除选项
    handleRemove(tag, parentObj, index) {
      this.recoveryItemStatus(tag, parentObj);
      parentObj.localStoreAnswer.splice(index, 1);
    },
    //恢复可选状态
    recoveryItemStatus(item, parentObj) {
      parentObj.choices.forEach(el => {
        if (el.value == item.value) {
          item.disabled = false;
          item.is_select = false;
        }
      });
    },
    // 提交
    async submit() {
      const isPass = this.checkIsChoose(this.qsItem);
      if (isPass) {
        this.getAnswerSelect(this.qsItem);
        const apiUrl = "/api/question/answer/";
        const postData = this.answersList;
        this.loading = true;
        try {
          const res = await this.$axios.post(apiUrl, postData);
          if (res.data.errno === 0) {
            this.$toast("恭喜您完成测评");
            this.loading = false;
            setTimeout(() => {
              this.toTestResult(this.testType);
            }, 1000)
          }
        } catch (error) {
          this.loading = false;
          console.log(error);
        }
      }
    },
    // ropenResultModal() {
    //   this.dialogVisible = true;
    // },
    toTestResult(type) {
      this.$router.push({ name: "MyEvaluations", params: { type: type } });
    }
  }
};
</script>

<style lang="scss" scoped>
.test_page {
  min-height: 100vh;
  .content {
    background: #fff;
    padding: 0.5rem 0.5rem 1rem;
    border-radius: 10px;
    min-height: 100vh;
    box-sizing: border-box;
    .total_num {
      text-align: center;
      font-size: 0.3rem;
    }
    .moveR-leave-to {
      position: absolute;
      transform: translateX(100%);
    }

    .el-button {
      width: 200px;
    }
    h2 {
      font-size: 15px;
      color: #111;
      margin-bottom: 20px;
      margin-top: 30px;
    }
    .red {
      color: #910000;
      font-weight: 600;
    }
    .qs_title {
      color: #111;
      font-size: 0.28rem;
      line-height: 0.44rem;
    }
    .sub_qs_title {
      height: 30px;
    }

    .btn {
      text-align: center;
      button {
        margin-top: 1rem;
      }
      .nouse_btn{
        background: #ce5d5d;
        border-color: #ce5d5d;
      }
    }

    ul {
      li {
        &.active_answer {
          border: 1px solid #910000 !important;
          background: #910000;
          color: #fff;
          &:hover {
            border-color: #910000 !important;
            color: #fff !important;
          }
        }
      }
    }

    ul.choices_items {
      margin-bottom: 0.5rem;
      li {
        border-radius: 5px;
        box-sizing: border-box;
        margin-bottom: 0.3rem;
        cursor: pointer;
        .el-button {
          width: 100%;
          padding: 14px 14px;
          &:hover {
            border-color: #910000;
            color: #910000;
          }
          &.is-disabled:hover {
            border-color: #ccc;
            color: #ccc;
          }
        }
      }
      &.single_select_qs {
        li {
          border: 1px solid #ccc;
          padding: 0.2rem 0.4rem;
        }
      }
    }

    .qs_answer_order_wrap {
      li {
        margin-bottom: 14px;
      }
      .el-tag {
        background-color: #fff;
        border-color: #910000;
        color: #910000;
        margin-right: 15px;

        height: 44px;
        padding: 6px 10px;
        font-size: 13px;
        .el-icon-close {
          color: #910000 !important;
          margin-right: 4px;
          &:hover {
            color: #fff !important;
          }
        }
        .el-tag__close:hover {
          background: #ce5d5d;
          color: #fff;
        }
      }
    }
  }
}
</style>
